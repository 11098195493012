import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { ExtraInformationSignpostsType } from '../../../models/extra-information-signposts-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { InformationBlockType } from '../../../models/information-block-type';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import InformationBlockGroup from '../../molecules/information-block-group/information-block-group';

interface Props extends ExtraInformationSignpostsType {
  readonly onSelect?: (block: InformationBlockType) => void;
  readonly preventDefault?: boolean;
}

const ExtraInformationSignposts: FC<Props> = ({
  callToAction,
  description,
  heading,
  informationBlocks,
  onSelect,
  preventDefault,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing">
      <h2
        className={
          `${description ? 'mb-2' : 'mb-16'} ` +
          'page-heading-three mx-auto text-center md:w-8/12 lg:w-6/12'
        }
      >
        {heading}
      </h2>

      {description && (
        <p className="mb-16 mx-auto text-center text-grey-600 w-full lg:w-8/12">
          {description}
        </p>
      )}

      <InformationBlockGroup
        blocks={informationBlocks}
        onSelect={(block: InformationBlockType) => {
          if (onSelect) {
            onSelect(block);
          } else {
            pushToDataLayer(GtmEventNameEnum.CardExtraInfo, {
              cardTitle: block.heading,
            });
          }
        }}
        preventDefault={preventDefault}
      />

      {callToAction && (
        <div className="flex justify-center mt-4 md:mt-6 lg:mt-16">
          <ButtonPrimary
            {...callToAction}
            onClick={() => {
              pushToDataLayer(GtmEventNameEnum.CardExtraInfo, {
                cardTitle: callToAction.name,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

ExtraInformationSignposts.defaultProps = {
  onSelect: null,
  preventDefault: false,
};

export default ExtraInformationSignposts;

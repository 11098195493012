import React, { FC } from 'react';
import { InformationBlockType } from '../../../models/information-block-type';
import InformationBlock from '../../atoms/information-block/information-block';

interface Props {
  readonly blocks: InformationBlockType[];
  readonly onSelect?: (block: InformationBlockType) => void;
  readonly preventDefault?: boolean;
}

const InformationBlockGroup: FC<Props> = ({
  blocks,
  onSelect,
  preventDefault,
}: Props) => (
  <div
    className={
      `${blocks.length < 3 ? 'mx-auto w-full lg:w-8/12' : 'w-full'} ` +
      'flex flex-col lg:flex-row items-start lg:items-center'
    }
  >
    {blocks.map((block: InformationBlockType) => (
      <InformationBlock
        key={block.heading}
        {...block}
        onLinkClick={onSelect && (() => onSelect(block))}
        preventDefault={preventDefault}
      />
    ))}
  </div>
);

InformationBlockGroup.defaultProps = {
  onSelect: null,
  preventDefault: false,
};

export default InformationBlockGroup;

import React, { FC, MouseEvent } from 'react';
import { InformationBlockType } from '../../../models/information-block-type';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import TextLink from '../text-link/text-link';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';

interface Props extends InformationBlockType {
  readonly onLinkClick?: () => void;
  readonly preventDefault?: boolean;
}

const InformationBlock: FC<Props> = ({
  guestDescription,
  description,
  heading,
  icon,
  link,
  onLinkClick,
  preventDefault,
}: Props) => {
  const isLoggedIn = useIsLoggedIn();
  const visibleDescription = isLoggedIn
    ? description
    : guestDescription ?? description;
  const iconClassNames =
    !visibleDescription && icon
      ? 'mb-0 lg:mb-4 ml-4 md:ml-0 mr-8 lg:mr-0'
      : 'mb-4';

  const headingClassNames = icon
    ? 'page-heading-six w-8/12'
    : 'page-heading-four w-full';

  let informationBlockInnerClassNames =
    'items-start text-left lg:items-center lg:text-center';
  if (visibleDescription && icon) {
    informationBlockInnerClassNames = 'items-center';
  } else if (visibleDescription && !icon) {
    informationBlockInnerClassNames = 'items-start';
  }

  return (
    <div
      className={
        `${icon ? 'text-center' : 'text-left'} ` +
        `${visibleDescription ? 'flex-col' : 'flex-row lg:flex-col'} ` +
        'flex items-center mb-12 lg:mb-0 mx-auto lg:mx-4 xl:mx-12 w-full md:w-8/12 lg:w-full'
      }
    >
      {icon && (
        <IcomoonIcon
          className={iconClassNames}
          color="turquoise"
          name={icon}
          size="3xl"
        />
      )}

      <div
        className={`${informationBlockInnerClassNames} flex flex-col w-full`}
      >
        <h6 className={`${headingClassNames} mb-2`}>{heading}</h6>

        {visibleDescription && (
          <p className="text-grey-600 mb-2">{visibleDescription}</p>
        )}

        {link && (
          <TextLink
            {...link}
            onClick={(event: MouseEvent) => {
              if (preventDefault) {
                event.stopPropagation();
                event.preventDefault();
              }

              if (onLinkClick) {
                onLinkClick();
              }
            }}
            showIcon={description && !icon}
          />
        )}
      </div>
    </div>
  );
};

InformationBlock.defaultProps = {
  onLinkClick: null,
  preventDefault: false,
};

export default InformationBlock;
